* {
  scroll-behavior: smooth;
}

html {
  box-sizing: border-box;
  text-align: center;
}

body {
  font-family: "Roboto", sans-serif;
  background-attachment: fixed;
  background-origin: inital;
  background-repeat: no repeat;
  min-height: 100dvh;
  background: rgb(255,234,32);
  background: linear-gradient(to top right, rgba(255,234,32,1) 0%, rgba(157,241,223,1) 49%, rgba(0,116,255,1) 100%);
  margin-top: 0;
  margin-bottom: 0;
}


/* SEARCH FORM */

.search-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h4 {
  color: #fff;
  letter-spacing: 4px;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.75);
}

.sf-section {
  min-width: 100%;
  margin: 2rem auto 3.5rem;
  max-width: max-content;
  color: #fff;
}

.sf-bg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 550px;
  max-width: 97vw;
  margin: 1.2rem auto;
  padding: 1.5rem 2rem 3rem;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
}

#tg-btn1, #tg-btn2, #tg-btn3, #tg-btn4, #tg-btn5 {
  font-size: 0.75rem;
  padding: 0.75rem;
}

@media only screen and (max-width: 700px){
  #sf-bg {
    min-width: 75vw;
  }
}

@media only screen and (max-width: 500px) {
  #sf-bg {
    max-width: 98%;
    margin-left: auto;
    margin-right: auto;
  }

  h4 {
    font-size: 0.9rem;
  }

  #sf-auto, #sf-budget, #sf-fly {
    min-width: none;
    max-width: 85%;
    font-size: 0.9rem;
  }

  #sf-btn {
    font-size: 0.7rem;
  }

  #tg-btn1, #tg-btn2, #tg-btn3, #tg-btn4, #tg-btn5 {
    font-size: 0.55rem;
    padding: 1rem 6px;
  }

}

@media only screen and (max-width: 415px) {
  #sf-bg {
    padding-left: auto; 
    padding-right: auto;
    padding-bottom: 1.8rem;
  }

  h4 {
    font-size: 0.7rem;
  }

  #sf-auto, #sf-budget, #sf-fly {
    min-width: none;
    max-width: 95%;
    font-size: 0.7rem;
  }

  #sf-btn {
    font-size: 0.7rem;
  }

  #tg-btn1, #tg-btn2, #tg-btn3, #tg-btn4, #tg-btn5 {
    font-size: 0.50rem;
    padding: 0.8rem 0.2rem;
  }
}

@media only screen and (max-width: 340px) {
  #tg-btn1, #tg-btn2, #tg-btn3, #tg-btn4, #tg-btn5 {
    font-size: 0.50rem;
    padding: 0.75rem 0.1rem;
  }
}

@media only screen and (max-width: 325px) {
  #tg-btn1, #tg-btn2, #tg-btn3, #tg-btn4, #tg-btn5 {
    font-size: 0.45rem;
    padding: 0.75rem 0.1rem;
  }
}

.logo-img-spin {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}


/*  RESULTS PAGE  */

#result-grid {
  margin-top: 0.2rem;
  margin-bottom: 2rem;
}

.dialog-bg {
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
}

#info-btn {
  margin-left: 0.8rem;
}

@media only screen and (max-width: 900px) {

  #dialog-content {
    padding: 6px;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  #opt-card {
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
  }

  #arrow {
    margin-left: auto;
    margin-right: auto;
  }

  #details-chip {
    padding: 0;
  }

  #divider {
    font-size: 10px;
  }

  #info-btn {
    font-size: 0.7rem;
    padding: 10px 6px;
    margin: 6px;
  }

  #btn-stack {
    direction: row;
  }

}

@media only screen and (max-width: 450px) {

  #dialog-content {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  #opt-chip {
    font-size: 1rem;
  }

  #details-chip {
    font-size: 0.7rem;
  }

  #info-btn {
    font-size: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

}

@media only screen and (max-width: 350px) {
  #dialog-content {
    padding: 0.5rem;
  }
}

/* ABOUT PAGE */

.about-bg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 550px;
  margin: 1.2rem auto 2.2rem;
  padding: 1.5rem 2rem 2.1rem;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(50px);
}

@media only screen and (max-width: 700px) {
  .about-bg {
    min-width: 75vw;
  }
};

@media only screen and (max-width: 500px) {
  .about-bg {
    max-width: 98%;
    margin-left: 30px;
    margin-right: 30px;
  }
}
  

.about-bg p  {
  color: #fff;
  letter-spacing: 1px;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.75);
  padding: 0.822rem 1.5rem;
  margin: auto;
}

.about-bg a[href] {
  color: #fff;
}

.about-bg a[href]:hover {
  color: black;
}


/* CONTACT PAGE */

.contact-bg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 550px;
  margin: 1.2rem auto 2.2rem;
  padding: 1.5rem 2rem 3rem;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(50px);
}

.contact-bg p, .fd-title, .fd-text {
  margin: auto;
  padding: 0.822rem 1.5rem 1rem;
  color: #fff;
  letter-spacing: 1px;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.75);
}

.cf-bg {
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(6px);
}

@media only screen and (max-width: 425px) {
  .contact-bg {
    padding-left: 0.5rem;
    padding-right:  0.5rem;
  }
}


/* HEADER */


.nav-bar, .nav-link {
  color: #fff;
  letter-spacing: 4px;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
  text-decoration: none;
}

.nav-link {
  margin: auto;
  color: #fff;
  font-weight: 600;
  font-size: 0.8rem;
}

.nav-link:hover {
  color: black;
}

.logo-img1, .logo-img2 {
padding-right: 0.5rem;
}

@media only screen and (max-width: 900px) {
  .logo-img1 {
    display: none;
  }

  .nav-link {
    color: rgba(0, 0, 0, 0.8);
    text-align: left;
    margin-left: 1px;
    padding: 0.75rem 0;
    text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
  }
}

@media only screen and (min-width: 900px) {
  .logo-img2 {
    display: none;
  }
}


/* FOOTER */

footer {
  bottom: 0;
  left: 0;
  margin-top: 1rem;
  width: 100%;
}

footer, footer a {
  color: #fff;
  font-size: 0.6rem;
  letter-spacing: 3px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  text-decoration: none;
}

footer a:hover {
  color: black;
}

footer p {
  margin-top: 0;
}
